import { useCallback, useRef } from "react";

/**
 * Ported from react-three-map.
 * @see https://github.com/RodrigoHamuy/react-three-map/blob/4d005cd1ab04e3549611d16aea9958a534838a8a/src/core/use-function.ts#L4
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const useFunction = <T extends (...args: any[]) => any>(callback: T): T => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback((...args: any[]) => {
    return callbackRef.current(...args);
  }, []) as T;
};
