import { datadogRum } from "@datadog/browser-rum";

import { DataChannelPacket } from "@skydio/pbtypes/pbtypes/cloud/services/skygateway/cloud_node_pb";
import { CHANNEL_PACKET_TYPES } from "@skydio/skybus/src/transport/livekit/livekit_transport_layer";

import type { RecvExtras, SendExtras, Transport } from "@skydio/skybus-experimental";

const sendMessageOverTransport = (
  id: string,
  channel: string,
  payload: Uint8Array,
  transport: Transport<SendExtras, RecvExtras>
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      const extras: SendExtras = {};

      extras.topic = `${CHANNEL_PACKET_TYPES.get(DataChannelPacket.Type.CONTROL)}/${channel}`;
      extras.extraDst = [channel];

      transport.send(id, payload, extras);
      resolve(true); // Return true on success
    } catch (error) {
      datadogRum.addError(error, {
        message: "Failed to send message over transport",
        id,
        channel,
      });
      reject(false); // Return false on failure
    }
  });
};

export default sendMessageOverTransport;
