"use client";

import { createContext, useContext, useRef } from "react";
import { useStore } from "zustand";

import { createRealTimeOpsStore } from "./createRealTimeOpsStore";

import type { ReactNode } from "react";
import type { RealTimeOpsStore } from "./createRealTimeOpsStore";

export type RealTimeOpsStoreApi = ReturnType<typeof createRealTimeOpsStore>;

export const RealTimeOpsStoreContext = createContext<RealTimeOpsStoreApi | undefined>(undefined);

export interface RealTimeOpsStoreProviderProps {
  children: ReactNode;
}

/**
 * We ensure this component is re-render-safe by checking value of reference, so that the store is
 * only created once. This component will only be rendered once per request on the server, but might
 * be re-rendered multiple times on the client if there are stateful client components located
 * above this component in the tree, or if this component also contains other mutable
 * state that causes a re-render.
 *
 * Note: creating a store per route would require creating and sharing the store at page (route)
 * component level. Try not to use this if you do not need to create a store per route.
 *
 * https://docs.pmnd.rs/zustand/guides/nextjs
 */
export const RealTimeOpsStoreProvider = ({ children }: RealTimeOpsStoreProviderProps) => {
  const storeRef = useRef<RealTimeOpsStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createRealTimeOpsStore(/* If you need any init */);
  }

  return (
    <RealTimeOpsStoreContext.Provider value={storeRef.current}>
      {children}
    </RealTimeOpsStoreContext.Provider>
  );
};

/**
 * Since the `useStore` hook is being returned, you cannot call `.getState()` on the store
 * outside of React components.
 *
 * See: https://github.com/pmndrs/zustand/discussions/2315
 */
export const useRealTimeOpsStore = <T,>(selector: (store: RealTimeOpsStore) => T): T => {
  const realTimeOpsStoreContext = useContext(RealTimeOpsStoreContext);

  if (!realTimeOpsStoreContext) {
    throw new Error(`useRealTimeOpsStore must be used within RealTimeOpsStoreProvider`);
  }

  return useStore(realTimeOpsStoreContext, selector);
};
