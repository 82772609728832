import type { Mission } from "@skydio/pbtypes/pbtypes/vehicle/skills/mission";
import type { SliceCreator } from "../zustandTypes";

export interface MissionsSlice {
  missionPreview: Mission | null;
  setMissionPreview: (mission: Mission | null) => void;
}

export const createMissionsSlice: SliceCreator<keyof MissionsSlice> = set => {
  return {
    missionPreview: null,
    setMissionPreview: newMission => {
      set(state => {
        state.missionPreview = newMission;
      });
    },
  };
};
