import { useEffect, useRef } from "react";

import { Map } from "mapbox-gl-new";
import type { MapEvents, MapOptions } from "mapbox-gl-new";

import "mapbox-gl-new/dist/mapbox-gl.css";

import { useNomadStore } from "./store";

// Set your mapbox token here
const defaultMapOptions: Partial<Props> = {
  initialViewState: {
    center: [-122.4194, 37.7749],
    bearing: 0,
    /** Maximum pitch of the map. */
    maxPitch: 85,
    /** Maximum zoom of the map. */
    maxZoom: 26,
    /** Minimum pitch of the map. */
    minPitch: 0,
    /** Minimum zoom of the map. */
    minZoom: 0,
    /**
     * The initial pitch (tilt) of the map, measured in degrees away from the plane of the
     * screen (0-85).
     */
    pitch: 30,
    /** Initial zoom level. */
    zoom: 18,
  },
  /**
   * If true, the gl context will be created with MSA antialiasing, which can be useful for
   * antialiasing custom layers. This is false by default as a performance optimization.
   */
  antialias: false,
  /** If true, an attribution control will be added to the map. */
  attributionControl: false,
  /** Snap to north threshold in degrees. */
  bearingSnap: 1,
  /** The initial bounds of the map. If bounds is specified, it overrides center and
   *  zoom constructor options. */
  bounds: undefined,
  /** If true, enable the "box zoom" interaction (see BoxZoomHandler) */
  boxZoom: false,
  /**
   * The max number of pixels a user can shift the mouse pointer during a click for it to be
   * considered a valid click (as opposed to a mouse drag).
   */
  clickTolerance: 1,
  /**
   * If `true`, Resource Timing API information will be collected for requests made by GeoJSON
   * and Vector Tile web workers (this information is normally inaccessible from the main
   * Javascript thread). Information will be returned in a `resourceTiming` property of
   * relevant `data` events.
   */
  collectResourceTiming: false,
  /**
   * If `true`, symbols from multiple sources can collide with each other during collision
   * detection. If `false`, collision detection is run separately for the symbols in each source.
   */
  crossSourceCollisions: true,
  /**
   * If `true` , scroll zoom will require pressing the ctrl or ⌘ key while scrolling to zoom map,
   * and touch pan will require using two fingers while panning to move the map.
   * Touch pitch will require three fingers to activate if enabled.
   */
  cooperativeGestures: false,
  /** String or strings to show in an AttributionControl.
   * Only applicable if options.attributionControl is `true`. */
  customAttribution: undefined,
  /**
   * If `true`, the "drag to pan" interaction is enabled.
   * An `Object` value is passed as options to {@link DragPanHandler#enable}.
   */
  dragPan: true,
  /** If true, enable the "drag to rotate" interaction (see DragRotateHandler). */
  dragRotate: true,
  /** If true, enable the "double click to zoom" interaction (see DoubleClickZoomHandler). */
  doubleClickZoom: true,
  /** If `true`, the map's position (zoom, center latitude, center longitude, bearing, and pitch)
   * will be synced with the hash fragment of the page's URL.
   * For example, `http://path/to/my/page.html#2.59/39.26/53.07/-24.1/60`.
   * An additional string may optionally be provided to indicate a parameter-styled hash,
   * e.g. http://path/to/my/page.html#map=2.59/39.26/53.07/-24.1/60&foo=bar, where foo
   * is a custom parameter and bar is an arbitrary hash distinct from the map hash.
   * */
  hash: false,
  /**
   * Controls the duration of the fade-in/fade-out animation for label collisions, in milliseconds.
   * This setting affects all symbol layers. This setting does not affect the duration of runtime
   * styling transitions or raster tile cross-fading.
   */
  fadeDuration: 300,
  /** If true, map creation will fail if the implementation determines that the performance
   *  of the created WebGL context would be dramatically lower than expected. */
  failIfMajorPerformanceCaveat: false,
  /** A fitBounds options object to use only when setting the bounds option. */
  fitBoundsOptions: undefined,
  /** If false, no mouse, touch, or keyboard listeners are attached to the map, so it will
   *  not respond to input */
  interactive: true,
  /** If true, enable keyboard shortcuts (see KeyboardHandler). */
  keyboard: true,
  /** A patch to apply to the default localization table for UI strings, e.g. control tooltips.
   * The `locale` object maps namespaced UI string IDs to translated strings in the target language,
   * see `src/ui/default_locale.js` for an example with all supported string IDs.
   * The object may specify all UI strings (thereby adding support for a new translation) or
   * only a subset of strings (thereby patching the default translation table).
   */
  locale: undefined,
  /**
   * Overrides the generation of all glyphs and font settings except font-weight keywords
   * Also overrides localIdeographFontFamily
   */
  localFontFamily: undefined,
  /**
   * If specified, defines a CSS font-family for locally overriding generation of glyphs in the
   * 'CJK Unified Ideographs' and 'Hangul Syllables' ranges. In these ranges, font settings from
   * the map's style will be ignored, except for font-weight keywords (light/regular/medium/bold).
   * The purpose of this option is to avoid bandwidth-intensive glyph server requests.
   */
  localIdeographFontFamily: undefined,
  /**
   * A string representing the position of the Mapbox wordmark on the map.
   * "bottom-left", "bottom-right", "top-left", "top-right".
   */
  logoPosition: "bottom-left",
  /** If set, the map is constrained to the given bounds. */
  maxBounds: undefined,
  /**
   * The maximum number of tiles stored in the tile cache for a given source. If omitted, the
   * cache will be dynamically sized based on the current viewport.
   */
  maxTileCacheSize: undefined,
  /** If true, the maps canvas can be exported to a PNG using map.getCanvas().toDataURL(),.
   *  This is false by default as a performance optimization. */
  preserveDrawingBuffer: false,
  /**
   * A style's projection property sets which projection a map is rendered in.
   */
  projection: {
    // "albers", "equalEarth", "equirectangular", "lambertConformalConic", "mercator",
    // "naturalEarth", "winkelTripel", "globe".
    name: "mercator",
    // Applies only to "albers", "lambertConformalConic".
    center: [0, 0],
    // Applies only to "albers", "lambertConformalConic".
    parallels: [0, 0],
  },
  /**
   * If `false`, the map's pitch (tilt) control with "drag to rotate" interaction will be disabled.
   */
  pitchWithRotate: true,
  /**
   * If `false`, the map won't attempt to re-request tiles once they expire per their HTTP
   * `cacheControl`/`expires` headers.
   */
  refreshExpiredTiles: true,
  /**
   * If `true`, multiple copies of the world will be rendered, when zoomed out.
   */
  renderWorldCopies: true,
  /**
   * If `true`, the "scroll to zoom" interaction is enabled.
   * An `Object` value is passed as options to {@link ScrollZoomHandler#enable}.
   */
  scrollZoom: true,
  /** Stylesheet url. */
  style: "mapbox://styles/mapbox/light-v8",
  /**
   * Allows for the usage of the map in automated tests without an accessToken with custom self-hosted test fixtures.
   */
  testMode: false,
  /** If  true, the map will automatically resize when the browser window resizes */
  trackResize: true,
  /**
   * A callback run before the Map makes a request for an external URL. The callback can be
   * used to modify the url, set headers, or set the credentials property for cross-origin requests.
   */
  transformRequest: undefined,
  /**
   * If `true`, the "pinch to rotate and zoom" interaction is enabled.
   * An `Object` value is passed as options to {@link TouchZoomRotateHandler#enable}.
   */
  touchZoomRotate: true,
  /**
   * If `true`, the "drag to pitch" interaction is enabled.
   * An `Object` value is passed as options to {@link TouchPitchHandler#enable}.
   */
  touchPitch: true,
};

/**
 * Ported from react-map-gl's `ViewportProps`
 * See https://github.com/visgl/react-map-gl/blob/0ef118b045f59da38fa3153a0456787e8788073d/src/types/common.ts#L75
 */
export interface ViewportProps {
  maxZoom?: MapOptions["maxZoom"];
  minZoom?: MapOptions["minZoom"];
  maxPitch?: MapOptions["maxPitch"];
  minPitch?: MapOptions["minPitch"];
  center?: MapOptions["center"];
  /** Map zoom level */
  zoom: MapOptions["zoom"];
  /** Map rotation bearing in degrees counter-clockwise from north */
  bearing: MapOptions["bearing"];
  /** Map angle in degrees at which the camera is looking at the ground */
  pitch: MapOptions["pitch"];
}

type OptionsProps = Omit<
  MapOptions,
  | "container"
  | "maxZoom"
  | "minZoom"
  | "maxPitch"
  | "minPitch"
  | "center"
  | "zoom"
  | "bearing"
  | "pitch"
>;
type EventsProps = Partial<{
  [key in keyof MapEvents as `on${Capitalize<key>}`]: (ev: MapEvents[key]) => void;
}>;
export type Props = OptionsProps &
  EventsProps & {
    initialViewState?: Partial<ViewportProps>;
  };

export function MapboxMap(props: Props) {
  const container = useRef(null);
  const mapRef = useRef<Map>();
  const mapStyleRef = useRef<string>();

  const setMapboxMap = useNomadStore(state => state.setMapboxMap);

  useEffect(() => {
    if (!container.current) {
      return;
    }

    // Update existing map.
    if (mapRef.current) {
      const map: Map = mapRef.current;
      const { options, events } = decodeProps(props);
      for (const [key, value] of events) {
        map.on(key, value);
      }
      // eslint-disable-next-line eqeqeq
      if (mapStyleRef.current != (options.style as string)) {
        map.setStyle(options.style as string, {
          diff: false,
          localFontFamily: undefined,
          localIdeographFontFamily: undefined,
        });
        mapStyleRef.current = options.style as string;
      }
      setOptions(map, options);

      return () => {
        for (const [key, value] of events) {
          map.off(key, value);
        }
      };
    }

    // Initialize map.
    const { options, events, initialViewState } = decodeProps({ ...defaultMapOptions, ...props });
    const map = new Map({
      ...options,
      ...initialViewState,
      container: container.current,
    });
    mapRef.current = map;
    mapStyleRef.current = options.style as string;
    for (const [key, value] of events) {
      map.on(key, value);
    }

    setMapboxMap(map);

    return () => {
      for (const [key, value] of events) {
        map.off(key, value);
      }
    };
  }, [props, container, setMapboxMap]);

  // Resize the map when its container is resized.
  useEffect(() => {
    if (!(container.current && mapRef.current)) {
      return;
    }

    let debounce: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(() => mapRef.current?.resize(), 50);
    };

    const resizer = new ResizeObserver(handleResize);
    resizer.observe(container.current);

    return () => {
      clearTimeout(debounce);
      resizer.disconnect();
    };
  }, [container, mapRef]);

  useEffect(() => {
    return () => {
      mapRef.current?.remove();
      mapRef.current = undefined;
      setMapboxMap(null);
    };
  }, []);

  return (
    <div
      ref={container}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );
}

/**
 * Set options on a map, using setters
 */
function setOptions(map: Map, options: OptionsProps) {
  for (const [key, value] of Object.entries(options)) {
    // Dont change style if it is different, mapbox downloads it so it's always different
    if (key === "style") continue;

    const upperFirstKey = `${key.slice(0, 1).toUpperCase()}${key.slice(1)}`;
    const getter = `get${upperFirstKey}`;
    const setter = `set${upperFirstKey}`;
    if (!(getter in map)) continue;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const previousValue = map[getter]();
    if (previousValue !== value) {
      // Dont change center if it is not different enough
      if (
        key === "center" &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Math.abs(previousValue.lat - (value.lat ?? value[1])) <= Number.MIN_VALUE &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Math.abs(previousValue.lng - (value.lng ?? value[0])) <= Number.MIN_VALUE
      ) {
        continue;
      }

      if (!(setter in map)) continue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map[setter](value);
    }
  }
}

type MapEventHandlerCouple<T extends keyof MapEvents> = [
  type: T,
  listener: (ev: MapEvents[T] & object) => void,
];

/**
 * Decode props, splitting between map options and map events
 */
function decodeProps(props: Props) {
  const options: OptionsProps = {};
  const events: MapEventHandlerCouple<keyof MapEvents>[] = [];
  let initialViewState: Partial<ViewportProps> = {};

  for (const [key, value] of Object.entries(props)) {
    if (key.startsWith("on") && key[2] === key[2]?.toUpperCase()) {
      const realKey = key as keyof EventsProps;
      events.push([
        (realKey.slice(2, 3).toLowerCase() + realKey.slice(3)) as keyof MapEvents,
        value as (ev: MapEvents[keyof MapEvents] & object) => void,
      ]);
    } else if (key === "initialViewState" && typeof value === "object") {
      initialViewState = value as Partial<ViewportProps>;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options[key] = value;
    }
  }

  return { options, events, initialViewState };
}
