import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { createAlertsSlice } from "./alerts/alertsSlice";
import { createDeviceChannelsSlice } from "./deviceChannels/deviceChannelsSlice";
import { createInteractionSlice } from "./interaction/interactionSlice";
import { createMissionsSlice } from "./missions/missionsSlice";
import { createVehicleDataSlice } from "./vehicleData/vehicleDataSlice";

import type { AlertsSlice } from "./alerts/alertsSlice";
import type { DeviceChannelsSlice } from "./deviceChannels/deviceChannelsSlice";
import type { InteractionSlice } from "./interaction/interactionSlice";
import type { MissionsSlice } from "./missions/missionsSlice";
import type { VehicleDataSlice } from "./vehicleData/vehicleDataSlice";

export interface RealTimeOpsStore
  extends DeviceChannelsSlice,
    VehicleDataSlice,
    InteractionSlice,
    AlertsSlice,
    MissionsSlice {
  /** If additional fields need to get merged in alongside slice creators */
}

/**
 * This is just the store creator, where slices are merged.
 * Components should import `useRealTimeOpsStore` from the provider instead.
 */
export const createRealTimeOpsStore = () =>
  create<RealTimeOpsStore>()(
    devtools(
      subscribeWithSelector(
        immer((set, get, store) => ({
          /** Additional fields can be added here */
          ...createDeviceChannelsSlice(set, get, store),
          ...createVehicleDataSlice(set, get, store),
          ...createInteractionSlice(set, get, store),
          ...createMissionsSlice(set, get, store),
          ...createAlertsSlice(set, get, store),
        }))
      ),
      {
        /**
         * Zustand's redux devtools middleware supports the following:
         * https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
         */
        name: "RealTimeOpsStore",
        latency: 1000,
        maxAge: 1,
        shouldRecordChanges: false,
      }
    )
  );
