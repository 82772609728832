import { faColumns, faMap, faVideo } from "@fortawesome/pro-regular-svg-icons";
import { enableMapSet } from "immer";

import type { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import type { GPSPoint } from "@skydio/math";
import type {
  DOCK_CAMERA_STREAM_PB,
  INFRARED_STREAM_PB,
  USER_CAMERA_STREAM_PB,
} from "@skydio/skybus/src/transport/utils";
import type { SliceCreator } from "../zustandTypes";

type Screen = "Map" | "Split" | "Video";
export type Overlay = "Operation" | "Markers";
/**
 * The different zones in the UI where the user can interact with entities.
 */
export type InteractionZone =
  | "map"
  | "sitesList"
  | "incidentsList"
  | "operationsList"
  | "dronesList"
  | "personnelList"
  | "operationIncidentsList"
  | "operationDronesList"
  | "droneSelectionModal";
export type SelectedEntity = { uuid: string; selectedIn: InteractionZone };
export type HoveredEntity = { uuid: string; hoveredIn: InteractionZone };

// Enable the MapSet plugin
enableMapSet();

export const screenConfig: Map<Screen, IconDefinition> = new Map([
  ["Map", faMap],
  ["Split", faColumns],
  ["Video", faVideo],
]);

export const screens = Object.keys(screenConfig) as (keyof Screen)[];

export type TrackType =
  | typeof DOCK_CAMERA_STREAM_PB
  | typeof INFRARED_STREAM_PB
  | typeof USER_CAMERA_STREAM_PB;

interface Interaction {
  screen: Screen;
  setScreen: (screen: Screen) => void;
  expandedOverlays: Set<Overlay>;
  toggleExpandedOverlay: (o: Overlay) => void;
  setExpandedOverlays: (overlays: Set<Overlay>) => void;
  showDroneGroup: boolean;
  setShowDroneGroup: (show: boolean) => void;
  // Currently used to store vehicleId for use in RFD iFrame
  selectedVehicleForRFD: string | null;
  // Sets the selected vehicle for RFD iFrame
  setSelectedVehicleForRFD: (droneId: string | null) => void;
  selectedOperationUuid: string | null;
  setSelectedOperationUuid: (operationId: string | null) => void;
  selectedEntity: SelectedEntity | null;
  setSelectedEntity: (entity: SelectedEntity | null) => void;
  hoveredEntity: HoveredEntity | null;
  setHoveredEntity: (entity: HoveredEntity | null) => void;
  activeTracks: Map<string, TrackType>;
  setActiveTrack: (vehicleId: string, trackType: TrackType) => void;
  flyToPOI: GPSPoint | null;
  setFlyToPOI: (flyToPOI: GPSPoint | null) => void;
  showFlyToPOIModal: boolean;
  setShowFlyToPOIModal: (show: boolean) => void;
}

export interface InteractionSlice {
  interaction: Interaction;
}

export const createInteractionSlice: SliceCreator<keyof InteractionSlice> = set => {
  return {
    interaction: {
      screen: "Map",
      setScreen: (screen: Screen) => {
        set(state => {
          state.interaction.screen = screen;
        });
      },
      showDroneGroup: false,
      setShowDroneGroup: (show: boolean) => {
        set(state => {
          state.interaction.showDroneGroup = show;
        });
      },
      selectedVehicleForRFD: null,
      setSelectedVehicleForRFD: (droneId: string | null) => {
        set(state => {
          state.interaction.selectedVehicleForRFD = droneId;
          // TODO(David): This might need to change where we call this
          state.interaction.screen = "Split";
        });
      },
      selectedOperationUuid: null,
      setSelectedOperationUuid: (operationUuid: string | null) => {
        set(state => {
          state.interaction.selectedOperationUuid = operationUuid;
        });
      },
      expandedOverlays: new Set<Overlay>(["Markers", "Operation"]),
      toggleExpandedOverlay: (o: Overlay) => {
        set(state => {
          const newExpandedOverlaySet = new Set<Overlay>(state.interaction.expandedOverlays);
          if (state.interaction.expandedOverlays.has(o)) {
            newExpandedOverlaySet.delete(o);
          } else {
            newExpandedOverlaySet.add(o);
          }
          state.interaction.expandedOverlays = newExpandedOverlaySet;
        });
      },
      setExpandedOverlays: (overlays: Set<Overlay>) => {
        set(state => {
          state.interaction.expandedOverlays = overlays;
        });
      },
      selectedEntity: null,
      setSelectedEntity: entity => {
        set(state => {
          state.interaction.selectedEntity = entity;
        });
      },
      hoveredEntity: null,
      setHoveredEntity: entity => {
        set(state => {
          state.interaction.hoveredEntity = entity;
        });
      },
      activeTracks: new Map(),
      setActiveTrack: (vehicleId, trackType) => {
        set(state => {
          state.interaction.activeTracks = state.interaction.activeTracks.set(vehicleId, trackType);
        });
      },
      flyToPOI: null,
      setFlyToPOI: (flyToPOI: GPSPoint | null) => {
        set(state => {
          state.interaction.flyToPOI = flyToPOI;
        });
      },
      showFlyToPOIModal: false,
      setShowFlyToPOIModal: (show: boolean) => {
        set(state => {
          state.interaction.showFlyToPOIModal = show;
        });
      },
    },
  };
};
