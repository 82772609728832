/**
 * Ported from ThreeBox
 * @see https://github.com/jscastro76/threebox/blob/2cdbd1f17865eef86c6cdeb0d4677029aac5b3d6/src/camera/CameraSync.js#L1
 */
import { clamp } from "@math.gl/core";
import * as THREE from "three";

import type { Map } from "mapbox-gl-new";

/**
 * Ported from https://github.com/jscastro76/threebox/blob/2cdbd1f17865eef86c6cdeb0d4677029aac5b3d6/src/utils/constants.js#L1
 */
const WORLD_SIZE = 1024000; // TILE_SIZE * 2000
const FOV_ORTHO = (0.1 / 180) * Math.PI; // Mapbox doesn't accept 0 as FOV
const FOV = Math.atan(3 / 4); // from Mapbox https://github.com/mapbox/mapbox-gl-js/blob/main/src/geo/transform.js#L93
const EARTH_RADIUS = 6371008.8; // from Mapbox https://github.com/mapbox/mapbox-gl-js/blob/0063cbd10a97218fb6a0f64c99bf18609b918f4c/src/geo/lng_lat.js#L11

export const ThreeboxConstants = {
  WORLD_SIZE: WORLD_SIZE,
  EARTH_CIRCUMFERENCE: 2 * Math.PI * EARTH_RADIUS, // In meters
  FOV_ORTHO: FOV_ORTHO, // closest to 0
  FOV: FOV, // Math.atan(3/4) radians. If this value is changed, FOV_DEGREES must be calculated
  FOV_DEGREES: (FOV * 180) / Math.PI, // Math.atan(3/4) in degrees
  TILE_SIZE: 512,
};

function makePerspectiveMatrix(fovy: number, aspect: number, near: number, far: number) {
  const out = new THREE.Matrix4();
  const f = 1.0 / Math.tan(fovy / 2),
    nf = 1 / (near - far);

  const newMatrix = [
    f / aspect,
    0,
    0,
    0,
    0,
    f,
    0,
    0,
    0,
    0,
    (far + near) * nf,
    -1,
    0,
    0,
    2 * far * near * nf,
    0,
  ];

  out.elements = newMatrix;
  return out;
}

export class CameraSync {
  private map: Map;
  private _camera: THREE.PerspectiveCamera;
  private world: THREE.Group;
  private cameraTranslateZ = new THREE.Matrix4();
  private halfFov: number;
  private cameraToCenterDistance: number;
  private translateCenter: THREE.Matrix4;
  private _worldSizeRatio: number;

  private __tempMatrix4 = new THREE.Matrix4();
  private __tempScaleMat = new THREE.Matrix4();
  private __tempRotateMat = new THREE.Matrix4();
  private __tempTranslateMat = new THREE.Matrix4();

  constructor(map: Map, camera: THREE.PerspectiveCamera, world?: THREE.Group) {
    this.map = map;
    this._camera = camera;

    this._camera.matrixAutoUpdate = false;
    this._camera.matrixWorldAutoUpdate = false;

    this.world = world || new THREE.Group();
    this.world.position.set(ThreeboxConstants.WORLD_SIZE / 2, ThreeboxConstants.WORLD_SIZE / 2, 0);
    this.world.matrixAutoUpdate = false;
    this.world.matrixWorldAutoUpdate = false;

    this.translateCenter = new THREE.Matrix4().makeTranslation(
      ThreeboxConstants.WORLD_SIZE / 2,
      -ThreeboxConstants.WORLD_SIZE / 2,
      0
    );
    this._worldSizeRatio = ThreeboxConstants.TILE_SIZE / ThreeboxConstants.WORLD_SIZE;

    this.update();
  }

  update() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const t = this.map.transform;
    this._camera.aspect = t.width / t.height;
    const offset = t.centerOffset || new THREE.Vector3();
    let farZ = 0;
    let furthestDistance = 0;
    this.halfFov = t._fov / 2;
    const groundAngle = Math.PI / 2 + t._pitch;
    const pitchAngle = Math.cos(Math.PI / 2 - t._pitch);
    this.cameraToCenterDistance = (0.5 / Math.tan(this.halfFov)) * t.height;
    let pixelsPerMeter = 1;
    const worldSize = this.worldSize();

    pixelsPerMeter = this.mercatorZfromAltitude(1, t.center.lat) * worldSize;
    const fovAboveCenter = t._fov * (0.5 + t.centerOffset.y / t.height);

    const minElevationInPixels = t.elevation
      ? t.elevation.getMinElevationBelowMSL() * pixelsPerMeter
      : 0;
    const cameraToSeaLevelDistance =
      (t._camera.position[2] * worldSize - minElevationInPixels) / Math.cos(t._pitch);
    const topHalfSurfaceDistance =
      (Math.sin(fovAboveCenter) * cameraToSeaLevelDistance) /
      Math.sin(clamp(Math.PI - groundAngle - fovAboveCenter, 0.01, Math.PI - 0.01));

    furthestDistance = pitchAngle * topHalfSurfaceDistance + cameraToSeaLevelDistance;
    const horizonDistance = cameraToSeaLevelDistance * (1 / t._horizonShift);
    farZ = Math.min(furthestDistance * 1.01, horizonDistance);

    this.cameraTranslateZ.makeTranslation(0, 0, this.cameraToCenterDistance);
    const nz = t.height / 50;
    const nearZ = Math.max(nz * pitchAngle, nz);

    const h = t.height;
    const w = t.width;
    this._camera.projectionMatrix = makePerspectiveMatrix(t._fov, w / h, nearZ, farZ);
    this._camera.projectionMatrix.elements[8] = (-offset.x * 2) / t.width;
    this._camera.projectionMatrix.elements[9] = (offset.y * 2) / t.height;
    this._camera.projectionMatrixInverse = this._camera.projectionMatrix.clone().invert();

    const cameraMatrix = this.calcCameraMatrix(
      t._pitch,
      t.angle,
      undefined,
      this._camera.matrix.identity()
    );
    if (t.elevation) cameraMatrix.elements[14] = t._camera.position[2] * worldSize;

    this._camera.updateMatrixWorld(true);

    const zoomPow = t.scale * this._worldSizeRatio;
    this.__tempScaleMat.makeScale(zoomPow, zoomPow, zoomPow);

    const x = t.point.x;
    const y = t.point.y;
    this.__tempTranslateMat.makeTranslation(-x, y, 0);
    this.__tempRotateMat.makeRotationZ(Math.PI);

    this.__tempMatrix4
      .identity()
      .premultiply(this.__tempRotateMat)
      .premultiply(this.translateCenter)
      .premultiply(this.__tempScaleMat)
      .premultiply(this.__tempTranslateMat);
    this.world.matrix.copy(this.__tempMatrix4);
    this.world.updateMatrixWorld(true);
  }

  private worldSize(): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const t = this.map.transform;
    return t.tileSize * t.scale;
  }

  private mercatorZfromAltitude(altitude: number, lat: number): number {
    return altitude / this.circumferenceAtLatitude(lat);
  }

  private circumferenceAtLatitude(latitude: number): number {
    return ThreeboxConstants.EARTH_CIRCUMFERENCE * Math.cos((latitude * Math.PI) / 180);
  }

  private calcCameraMatrix(
    pitch?: number,
    angle?: number,
    // unused, but keeping for consistency
    trz?: THREE.Matrix4,
    target?: THREE.Matrix4
  ): THREE.Matrix4 {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const t = this.map.transform;
    const _pitch = pitch === undefined ? t._pitch : pitch;
    const _angle = angle === undefined ? t.angle : angle;
    const _trz = trz === undefined ? this.cameraTranslateZ : trz;

    const _target = target ?? new THREE.Matrix4();
    return (
      _target
        .premultiply(_trz)
        // We just reuse these matrices here to avoid creating new ones
        .premultiply(this.__tempRotateMat.makeRotationX(_pitch))
        .premultiply(this.__tempScaleMat.makeRotationZ(_angle))
    );
  }
}
