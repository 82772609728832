import type { Channel } from "@skydio/channels";
import type { RecvExtras, SendExtras, SkybusTunnel, Transport } from "@skydio/skybus-experimental";
import type { SliceCreator } from "../zustandTypes";

interface ChannelIndexEntry<TMessage> {
  channel: Channel<TMessage>;
  refCount: number;
}

interface DeviceChannelStore {
  latest: { [key: string]: any }; // TODO(trey + david-tsai-skydio): how can any be T from Channel<T> based on key?
  // NOTE(trey): we wouldn't need this index if there was gencode for a full channel index; right now,
  // this is required because we can't look up the deserializer for a channel by name in TS like we
  // can in python, so we rely on clients to provide it when subscribing to a channel
  index: { [key: string]: ChannelIndexEntry<any> }; // separate from subscription to avoid triggering updated when adding new subscribers to an existing subscription
}

export class NoopTransport implements Transport<SendExtras, RecvExtras> {
  id() {
    return "no-op-transport";
  }

  send(dst: string, payload: Uint8Array, extras?: SendExtras): void {
    return;
  }

  addMessageListener(): () => void {
    return () => undefined;
  }
}

export interface DeviceChannelsSlice {
  deviceChannels: {
    /**
     * Adds a tunnel to the store.
     * @returns a function to remove the tunnel
     */
    addTunnel(...tunnels: Array<SkybusTunnel>): () => void;
    /** Subscribe to a channel from device, returns an unsubscribe function. */
    subscribe<TMessage>(deviceId: string, channel: Channel<TMessage>): () => void;
    /** Get the latest value on a channel from a specific device. */
    getChannelLatestById<TMessage>(deviceId: string, channel: Channel<TMessage>): TMessage | null;

    __SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED: {
      /** Channels feature device channel data by device id. */
      _channels: { [key: string]: DeviceChannelStore };
      /** Shimmed Transport  */
      _transport: Transport<SendExtras, RecvExtras>;
      /** Skybus tunnels */
      _tunnels: { [key: string]: SkybusTunnel };
      /** set the transport for tunnels to use */
      _setTransport(transport: Transport<SendExtras, RecvExtras>): void;
      /** clear the transport for tunnels to use */
      _clearTransport(): void;
      /** set the latest value on a channel from a specific device */
      _setChannelLatest(deviceId: string, channel: string, payload: Uint8Array): void;
    };
  };
}

/**
 * Zustand slice for managing shared, real-time telemetry data from Skybus.
 *
 * This slice handles setting and clearing the transport, updating the latest
 * channel data, and subscribing to channels for specific devices.
 *
 * @warning **DO NOT** modify this store directly. Use the provided utilities and hooks instead.
 */
export const createDeviceChannelsSlice: SliceCreator<keyof DeviceChannelsSlice> = (set, get) => {
  return {
    deviceChannels: {
      addTunnel: (...tunnels) => {
        set(state => {
          tunnels.forEach(tunnel => {
            const dst = tunnel.getDst();
            console.debug(`Adding tunnel for deviceId: ${dst}`);
            state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._tunnels[dst] =
              tunnel;
          });
        });

        return () =>
          set(state => {
            tunnels.forEach(tunnel => {
              const dst = tunnel.getDst();
              console.debug(`Removing tunnel for deviceId: ${dst}`);
              delete state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._tunnels[
                dst
              ];
            });
          });
      },
      subscribe: (deviceId, channel) => {
        set(state => {
          if (
            !state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels.hasOwnProperty(
              deviceId
            )
          ) {
            state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
              deviceId
            ] = { latest: {}, index: {} };
          }

          console.debug("subscribing to channel", { id: deviceId, channel: channel.channel });
          if (
            !state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
              deviceId
            ].index.hasOwnProperty(channel.channel)
          ) {
            state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
              deviceId
            ].index[channel.channel] = { refCount: 0, channel };
          }
          state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
            deviceId
          ].index[channel.channel].refCount += 1;
        });

        return () =>
          set(state => {
            if (
              !state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels.hasOwnProperty(
                deviceId
              )
            ) {
              console.warn("tried to unsubscribe from channel for unknown device", {
                id: deviceId,
                channel: channel.channel,
              });
              return;
            }

            if (
              !state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
                deviceId
              ].index.hasOwnProperty(channel.channel)
            ) {
              console.warn("tried to unsubscribe from already unsubscribed channel", {
                id: deviceId,
                channel: channel.channel,
              });
              return;
            }

            console.debug("unsubscribing from channel", { id: deviceId, channel: channel.channel });
            state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
              deviceId
            ].index[channel.channel].refCount -= 1;
          });
      },

      getChannelLatestById: (deviceId, channel) => {
        if (!deviceId) {
          return null;
        }

        return (
          get().deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[deviceId]
            ?.latest[channel.channel] ?? null
        );
      },

      __SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED: {
        _channels: {},
        _transport: new NoopTransport(),
        _tunnels: {},
        _setTransport: transport =>
          set(state => {
            state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._transport =
              transport;
          }),
        _clearTransport: () =>
          set(state => {
            state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._transport =
              new NoopTransport();
          }),
        _setChannelLatest: (deviceId, channel, payload) =>
          set(state => {
            // TODO(trey): should be a rollup
            // console.debug("received message", { size: payload.length, channel, id });

            if (
              !state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
                deviceId
              ]?.index.hasOwnProperty(channel)
            ) {
              console.debug("received message for unsubscribed channel", { channel });
              return;
            }

            try {
              const message =
                state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
                  deviceId
                ].index[channel].channel.type(payload);
              state.deviceChannels.__SECRET_DO_NOT_USE_OR_YOU_WILL_BE_DEACTIVATED._channels[
                deviceId
              ].latest[channel] = message;
            } catch (e) {
              console.error("error decoding message for channel", { channel, e });
              return;
            }
          }),
      },
    },
  };
};
