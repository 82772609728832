import type { ActiveAlertDetails, AlertId } from "state/teleop/alertDefinitions";
import type { SliceCreator } from "../zustandTypes";

type AlertMap = { [vehicleId: string]: ActiveAlertDetails };

export interface AlertsSlice {
  alerts: { [alertId: string]: AlertMap };
  createAlert: (alertId: AlertId, vehicleId: string, alert: ActiveAlertDetails) => void;
  removeAlert: (alertId: AlertId, vehicleId: string) => void;
  setAlertPlayedAt: (alertId: AlertId, vehicleId: string, playedAt: string) => void;
  toggleMuteOnAlert: (alertId: AlertId, vehicleId: string) => void;
}

export const createAlertsSlice: SliceCreator<keyof AlertsSlice> = (set, get, store) => {
  const createAlert = (alertId: AlertId, vehicleId: string, alert: ActiveAlertDetails) =>
    set(state => {
      if (!state.alerts[alertId]) {
        state.alerts[alertId] = {};
      }
      // don't want to overwrite alert if it already exists
      if (state.alerts[alertId][vehicleId] === undefined) {
        state.alerts[alertId][vehicleId] = alert;
      }
    });

  const removeAlert = (alertId: AlertId, vehicleId: string) =>
    set(state => {
      if (state.alerts[alertId] && state.alerts[alertId][vehicleId]) {
        delete state.alerts[alertId][vehicleId];
      }
    });

  const setAlertPlayedAt = (alertId: AlertId, vehicleId: string, playedAt: string) =>
    set(state => {
      const alert = state.alerts[alertId][vehicleId];
      if (alert) {
        if (!alert.audioPlayedAt) {
          alert.audioPlayedAt = [];
        }
        alert.audioPlayedAt.push(playedAt);
      }
    });

  const toggleMuteOnAlert = (alertId: AlertId, vehicleId: string) =>
    set(state => {
      const alert = state.alerts[alertId][vehicleId];
      if (alert) {
        alert.mutedAt = alert.mutedAt != null ? null : new Date().toISOString();
      }
    });

  return {
    alerts: {},
    createAlert,
    removeAlert,
    setAlertPlayedAt,
    toggleMuteOnAlert,
  };
};
